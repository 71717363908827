<template>
  <v-card-text class="text-subtitle-1 pt-0 pb-10">
    Work items
    <v-data-table
      density="compact"
      :headers="headers"
      :items="repair?.repairWorks || []"
      :disable-pagination="true"
      :hide-default-footer="true"
      items-per-page="-1"
      :mobile-breakpoint="0"
    >
      <template #no-data>
        <div class="text-body-2 text-disabled text-left">No work items linked yet</div>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <div>
          {{ `${moment(item.createdAt).format("YYYY-MM-DD HH:mm")} (${item.createdBy})` }}
        </div>
      </template>

      <template v-if="!props.readonly" v-slot:[`item.actions`]="{ index }">
        <div class="d-flex justify-end">
          <v-btn @click.stop="() => deleteWorkItem(index)" icon density="compact" size="small" variant="text" class="ml-2">
            <v-icon size="small">mdi-close-thick</v-icon>
          </v-btn>
        </div>
      </template>

      <template #bottom></template>
    </v-data-table>

    <div v-if="!props.readonly" class="d-flex d-flex align-center mt-4">
      <v-autocomplete
        v-model="selectedWorkItem"
        label="Add work item"
        item-title="name"
        item-value="repairWorkTypeId"
        return-object
        :items="workItems"
        density="compact"
        max-width="40%"
        class="select"
        filterable
        clearable
        no-data-text="No work items found"
        hide-details
        :loading="workItemsLoading"
        @update:modelValue="onSelect"
        ref="autocomplete"
        :error="!!errors.work"
        :error-messages="errors.work"
      >
        <template v-slot:selection="{ item }">
          <span class="itemWork">{{ item.raw.name }}</span>
        </template>
      </v-autocomplete>

      <v-text-field
        v-if="selectedWorkItem && !selectedWorkItem.repairWorkTypeId"
        v-model="newWorkItemName"
        label="Work item name"
        density="compact"
        hide-details
        :error="!!newItemError"
        max-width="40%"
        class="ml-5 pt-0"
      ></v-text-field>

      <div>
        <v-btn
          class="ml-2"
          density="comfortable"
          size="small"
          icon="mdi-plus"
          variant="elevated"
          color="white"
          @click="addWorkItemToRepair"
        ></v-btn>
      </div>
    </div>
  </v-card-text>
</template>

<script setup lang="ts">
import moment from "moment";
import { ref, watch } from "vue";
import axios, { CancelTokenSource } from "axios";
import RepairWork from "@/types/RepairWork";
import repairResource from "@/resources/RepairResource";
import Repair from "@/types/Repair";
import RepairWorkType from "@/types/RepairWorkType";
import authService from "@/services/AuthService";
import type { VDataTable } from "vuetify/components";

type ReadonlyHeaders = VDataTable["$props"]["headers"];

const emit = defineEmits(["close:editRepair", "select", "changeName"]);
const props = withDefaults(defineProps<{ repair?: Repair | null; readonly: boolean; errors?: { work: string } }>(), {
  repair: null,
  readonly: false,
  errors: () => ({ work: "" }),
});

const workItemsLoading = ref(false);
let workItemsToken: CancelTokenSource | undefined = undefined;
const workItems = ref<RepairWorkType[]>([]);
const selectedWorkItem = ref<RepairWorkType | null>(null);
const headers: ReadonlyHeaders = [
  { title: "Name", align: "start", key: "name", sortable: false },
  { title: "Date", key: "createdAt", sortable: false },
  { title: "", key: "actions", sortable: false },
];

const newWorkItemName = ref("");
const newItemError = ref("");

const autocomplete = ref(null);
const onSelect = () => {
  //@ts-ignore
  autocomplete?.value?.blur();
  props.errors.work = "";
};

const deleteWorkItem = (index: number) => {
  if (!props.repair) return;
  props.repair.repairWorks = props.repair.repairWorks.filter((v: RepairWork, ind) => index !== ind);
};

const getWorkItems = () => {
  if (workItemsToken) {
    workItemsToken.cancel();
  }

  workItemsToken = axios.CancelToken.source();
  workItemsLoading.value = true;
  repairResource
    .getRepairWorkTypes(workItemsToken)
    .then((resp) => {
      workItems.value = [
        ...resp.data,
        {
          repairWorkTypeId: 0,
          createdAt: new Date(),
          createdBy: authService.authInfo.username || "",
          name: "New work item",
        },
      ];
    })
    .catch(repairResource.defaultErrorHandler)
    .finally(() => {
      workItemsLoading.value = false;
      workItemsToken = undefined;
    });
};

watch(newWorkItemName, () => {
  if (newWorkItemName.value && newItemError.value) {
    newItemError.value = "";
  }
});

watch(selectedWorkItem, () => {
  emit("select", selectedWorkItem.value);
});

watch(
  () => props.repair,
  () => {
    if (!props.repair) return;
    getWorkItems();
  },
  { immediate: true }
);

const clearWorkItemSelect = () => {
  selectedWorkItem.value = null;
  newWorkItemName.value = "";
  newItemError.value = "";
};

const addWorkItemToRepair = () => {
  props.errors.work = "";
  if (selectedWorkItem.value && !selectedWorkItem.value.repairWorkTypeId && !newWorkItemName.value) {
    newItemError.value = "Field is required";
    return;
  }
  if (selectedWorkItem.value) {
    props.repair?.repairWorks?.push({
      repairWorkId: 0,
      repairWorkTypeId: selectedWorkItem.value.repairWorkTypeId,
      name: newWorkItemName.value || selectedWorkItem.value.name,
      createdAt: new Date(),
      createdBy: authService.authInfo.username || "",
    });
    clearWorkItemSelect();
  }
};
</script>
<style scoped>
.itemWork {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:deep(.v-field--focused) .v-autocomplete__selection {
  width: 30%;
}
</style>
