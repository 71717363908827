<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            variant="underlined"
            color="primary"
            label="Search"
            hide-details
            clearable
            @click:clear="search()"
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
          >
            <template v-slot:append-inner>
              <v-tooltip location="bottom" z-index="99999">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    v-model="isActiveFilter"
                    @click="isActiveFilter = !isActiveFilter"
                    class="mr-1"
                    tooltip="Ignore filters"
                    >{{ isActiveFilter ? "mdi-filter-check" : "mdi-filter-remove" }}</v-icon
                  >
                </template>
                {{ isActiveFilter ? "Using filters when searching" : "Ignoring filters when searching" }}
              </v-tooltip>
              <v-icon @click="search(true)">mdi-magnify</v-icon>
            </template>
          </v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="poiTableColumns" />
          <span class="align-self-end ml-0 p-relative">
            <v-btn
              size="small"
              variant="text"
              @click="() => showFiltersMenu()"
              :color="showFilters || filterPresetsSelected !== 'all' ? 'primary' : ''"
              title="Show filters"
              :disabled="Boolean(searchTerm && !isActiveFilter)"
              :ripple="false"
              flat
              class="px-1 filter"
            >
              <template v-slot:prepend>
                <v-icon size="24" class="mr-n2" v-if="searchTerm && !isActiveFilter">mdi-filter-off</v-icon>
                <v-icon size="24" class="mr-n2" v-if="!searchTerm || isActiveFilter">mdi-filter</v-icon>
              </template>

              <span
                v-if="!searchTerm"
                class="d-none d-sm-block filter-preset-label"
                :title="getSelectedFilterPresetText()"
                >{{ getSelectedFilterPresetText() }}</span
              >
            </v-btn>
          </span>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn
            v-if="canAddPoi"
            size="small"
            color="primary"
            @click="newPoi()"
            :disabled="currentLocationLoading"
            :loading="currentLocationLoading"
          >
            New POI
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ getPoiTypeDisplayName(item.type) }}
        </template>
        <template v-slot:[`item.timeToLive`]="{ item }">
          <span :title="item.timeToLive ? moment(item.timeToLive).format('lll') : ''">{{
            getTtlMinutes(item.timeToLive)
          }}</span>
        </template>
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.updatedDate`]="{ item }">
          {{ item.updatedDate ? moment(item.updatedDate).format("lll") : "" }}
        </template>

        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay :model-value="loading" opacity="0" contained persistent style="z-index: 999 !important" />
    </v-card>

    <EditPoi v-model="poiToEdit" v-on:updated="reload" @edit-another-poi="editPoi" :poiInitTab="poiInitTab" />

    <SideSheet v-model="showFilters" heading="Filter POI" contentClass="filters">
      <h4>Filter presets</h4>
      <v-select
        attach
        v-model="c_filterPresetsSelected"
        :items="filterPresetsItems"
        density="compact"
        hide-details
        @update:modelValue="applyFilterPreset()"
      />

      <h4 class="mt-6">POI Types</h4>
      <div>
        <v-btn size="x-small" link @click="selectAllPoiTypes()">Select all</v-btn> /
        <v-btn size="x-small" link @click="deselectAllPoiTypes()">Deselect all</v-btn>
      </div>
      <div v-for="type in typeItems" :key="type.value">
        <v-checkbox
          v-model="c_typeSelected"
          :label="type.text"
          :value="type.value"
          color="primary"
          density="compact"
          class="type"
          hide-details
          @click="setCustomFilterPreset()"
        />
      </div>

      <h4 class="mt-6">Active status</h4>
      <v-select
        v-model="c_activeSelected"
        :items="activeItems"
        density="compact"
        hide-details
        class="filter-item"
        @update:model-value="setCustomFilterPreset()"
      />

      <template v-slot:actions>
        <v-spacer />
        <v-btn variant="text" @click="resetFilters()">Reset</v-btn>
        <v-btn color="primary" class="ml-4" @click="applyFilters(true)">Apply</v-btn>
      </template>
    </SideSheet>

    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative } from "vue-facing-decorator";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import Poi from "@/types/Poi";
import { PoiType } from "@/types/PoiType";
import poiResource from "@/resources/PoiResource";
import PoiHelper from "@/helpers/poiHelper";
import EditPoi from "@/components/poi/EditPoi.vue";
import SideSheet from "@/components/layout/SideSheet.vue";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { nextTick } from "vue";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";

@Component({
  name: "PoiList", // name is needed for keep-alive
  components: {
    EditPoi,
    SideSheet,
    DataTableContextMenu,
    TableConfiguration,
    DataTableFooter,
  },
})
class PoiList extends Vue {
  @Prop({ default: null })
  initData!: { poiTab: string; poiId: number } | null;
  moment = moment;

  total = 0;
  items: Poi[] = [];
  loading = false;
  currentLocationLoading = false;

  optionsStorageKey = "poiTable";
  itemsPerPage = userStorage.get(this.optionsStorageKey)?.itemsPerPage ?? 15;

  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = userStorage.get(this.optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(this.optionsStorageKey)?.sortBy
    : [{ key: "poiId", order: true }];

  page = userStorage.get(this.optionsStorageKey)?.page ?? 1;

  searchFiltersActiveStorageKey = "poiTableSearchFiltersActive";
  isActiveFilter = userStorage.get(this.searchFiltersActiveStorageKey) ?? false;

  searchTermStorageKey = "poiTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;

  poiToEdit: Poi | null = null;
  poiInitTab: string | null = null;
  showFilters = false;

  filterPresetStorageKey = "poiTableFilterPreset";
  filterPresetsSelected = userStorage.get(this.filterPresetStorageKey) ?? "active";
  c_filterPresetsSelected = this.filterPresetsSelected;
  filterPresetsItems = [
    { title: "All POI", value: "all" },
    { title: "Active non-static POI only", value: "active" },
    { title: "Static POI only", value: "static" },
    { title: "Custom", value: "custom" },
  ];

  typeSelectedStorageKey = "poiTableTypeSelected";
  typeSelected = (userStorage.get(this.typeSelectedStorageKey) ?? []) as PoiType[];
  c_typeSelected = this.typeSelected;
  typeItems = [
    { text: this.getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
    { text: this.getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
    { text: this.getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
    { text: this.getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
    { text: this.getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
    { text: this.getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
    { text: this.getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
    { text: this.getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
    { text: this.getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
    { text: this.getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
  ];

  activeSelectedStorageKey = "poiTableActiveSelected";
  activeSelected = userStorage.get(this.activeSelectedStorageKey) ?? "any";
  c_activeSelected = this.activeSelected;
  activeItems = [
    { title: "Any", value: "any" },
    { title: "Active only", value: "active" },
    { title: "Inactive only", value: "inactive" },
  ];

  ignoreOptionsChange: boolean = false;

  @Watch("itemsPerPage")
  @Watch("sortBy")
  @Watch("page")
  async onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      await nextTick();
      this.getData();
    }
  }

  @Watch("isActiveFilter")
  onIsActiveFilterChanged() {
    if (this.searchTerm) {
      this.getData();
    }
  }

  @Watch("poiToEdit")
  onChangeCustomerToEdit() {
    if (!this.poiToEdit) {
      this.poiInitTab = null;
    }
  }

  selectedHeaders = [];
  headers = [
    { title: "ID", key: "poiId" },
    { title: "Type", key: "type" },
    { title: "Area info", key: "areaInfo" },
    { title: "Search tags", key: "searchTags" },
    { title: "Active", key: "isActive" },
    { title: "TTL", key: "timeToLive" },
    { title: "Device ID", key: "deviceId" },
    { title: "Negated", key: "numberOfNegativeReports" },
    { title: "Created", key: "createdDate" },
    { title: "Updated", key: "updatedDate" },
  ];

  get canAddPoi() {
    return userProfileService.hasPermission(UserPermissionType.AddPoi);
  }

  created() {
    this.applyFilterPreset();
    this.applyFilters(false);
  }

  mounted() {
    if (this.initData?.poiId) {
      this.getInitPoiById(this.initData?.poiId);
    }
  }

  dataReloadTimeoutId: number | null = null;
  dataReloadIntervalSeconds = 60;
  componentActive = false;
  activated() {
    this.componentActive = true;
    // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0 || this.dataReloadTimeoutId === null) {
      this.getData();
    }
  }

  deactivated() {
    this.componentActive = false;
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.getData();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getInitPoiById(poiId: number) {
    poiResource
      .getPoiById(poiId)
      .then((resp) => {
        this.rowClick(resp.data);
        this.poiInitTab = this.initData?.poiTab || null;
      })
      .catch(poiResource.defaultErrorHandler);
  }

  editPoi(poi: Poi) {
    this.poiToEdit = null;
    // use timeout to ensure poi is reset
    setTimeout(() => {
      this.poiToEdit = Object.assign({}, poi);
    }, 100);
  }

  getPoiTypeDisplayName(type: PoiType) {
    return PoiHelper.getPoiTypeDisplayName(type);
  }
  getTtlMinutes(ttl: Date | undefined): string {
    const ttlMin = PoiHelper.getTtlMinutes(ttl);
    if (ttlMin <= 0) {
      return "-";
    } else if (ttlMin > 1000) {
      return "~";
    } else {
      return `${ttlMin.toFixed(0)} min`;
    }
  }

  async newPoi() {
    if ("permissions" in navigator) {
      this.currentLocationLoading = true;
      try {
        const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
        let position: GeolocationPosition | null = null;
        if (permissionStatus.state === "granted") {
          position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
        }
        console.log('permissionStatus.state === ', permissionStatus)
        const latitude = Number(position?.coords?.latitude?.toFixed(6));
        const longitude = Number(position?.coords?.longitude?.toFixed(6));

        this.editPoi(PoiHelper.getNewPoiTemplate(latitude, longitude));
      } catch (error: any) {
        console.error(error.message || "Error in PoiList newPoi function");
      }
      this.currentLocationLoading = false;
    } else {
      this.editPoi(PoiHelper.getNewPoiTemplate());
    }
  }

  getData(resetPagination: boolean = false) {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.page = 1;
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
    });
    userStorage.set(this.searchTermStorageKey, this.searchTerm);
    userStorage.set(this.searchFiltersActiveStorageKey, this.isActiveFilter);
    userStorage.set(this.filterPresetStorageKey, this.filterPresetsSelected);
    userStorage.set(this.typeSelectedStorageKey, this.typeSelected);
    userStorage.set(this.activeSelectedStorageKey, this.activeSelected);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      // Filters
      var isActive: boolean | undefined = undefined;
      var typeOf: PoiType[] | undefined = undefined;

      if (!this.searchTerm || this.isActiveFilter) {
        // isActive
        if (this.activeSelected === "active") {
          isActive = true;
        } else if (this.activeSelected === "inactive") {
          isActive = false;
        }
        // typeOf
        if (this.filterPresetsSelected !== "all") {
          typeOf = this.typeSelected;
        }
      }

      const orderDesc =
        typeof this.sortBy[0].order === "boolean" ? this.sortBy[0].order : this.sortBy[0].order.toString() === "desc";
      poiResource
        .getPoiPaged(
          this.itemsPerPage,
          this.page,
          isActive,
          typeOf,
          this.searchTerm,
          this.sortBy[0].key,
          orderDesc,
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(poiResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData(true);
      }, 1000);
    }
  }

  showFiltersMenu() {
    this.c_filterPresetsSelected = this.filterPresetsSelected;
    this.c_typeSelected = [...this.typeSelected];
    this.c_activeSelected = this.activeSelected;

    this.showFilters = true;
  }

  resetFilters() {
    this.c_filterPresetsSelected = "active";
    this.applyFilterPreset();
    this.applyFilters(true);
  }

  applyFilters(reloadPoi: boolean) {
    this.filterPresetsSelected = this.c_filterPresetsSelected;
    this.typeSelected = [...this.c_typeSelected];
    this.activeSelected = this.c_activeSelected;

    if (reloadPoi) {
      this.showFilters = false;
      this.reload();
    }
  }

  setCustomFilterPreset() {
    this.c_filterPresetsSelected = "custom";
  }

  selectAllPoiTypes() {
    this.c_typeSelected = this.typeItems.map((el) => el.value);
    this.setCustomFilterPreset();
  }

  deselectAllPoiTypes() {
    this.c_typeSelected = [];
    this.setCustomFilterPreset();
  }

  applyFilterPreset() {
    if (this.c_filterPresetsSelected === "all") {
      this.c_typeSelected = this.typeItems.map((el) => el.value);
      this.c_activeSelected = "any";
    } else if (this.c_filterPresetsSelected === "active") {
      this.c_typeSelected = PoiHelper.getNonPermanentPoiTypes();
      this.c_activeSelected = "active";
    } else if (this.c_filterPresetsSelected === "static") {
      this.c_typeSelected = PoiHelper.getPermanentPoiTypes();
      this.c_activeSelected = "any";
    }
  }

  getSelectedFilterPresetText() {
    return this.filterPresetsItems.find((item) => item.value === this.filterPresetsSelected)?.title;
  }

  reload() {
    this.getData();
  }

  rowClick(item: Poi) {
    if (!this.contextMenuEventItem) {
      this.editPoi(item);
    }
  }

  rowClass({ item }: { item: Poi }) {
    return { class: { "cursor-default": true } };
  }
}

export default toNative(PoiList);
</script>

<style scoped>
.filter-preset-label {
  position: "absolute";
  left: 100%;
  margin-left: 4px;
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  text-align: left;
}

.filter {
  margin-bottom: 2px;
}

.type {
  font-size: 13px;
}
</style>
